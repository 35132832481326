.sobre-section {
  padding: 80px 0;
  background: #0A0F16;
  position: relative;
  overflow: hidden;
}

.sobre-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.sobre-header {
  text-align: center;
  margin-bottom: 60px;
}

.sobre-header h2 {
  color: #E6EDF3;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.title-bar {
  width: 60px;
  height: 3px;
  background: #4FC1FF;
  margin: 0 auto;
  border-radius: 2px;
}

.sobre-content {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.sobre-text {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.sobre-intro {
  color: #4FC1FF;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.sobre-description {
  color: #A3B3BC;
  font-size: 1.1rem;
  line-height: 1.8;
}

.stats-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  width: 100%;
  flex-wrap: nowrap;
}

.stat-card {
  flex: 1;
  min-width: 200px;
  max-width: 250px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 25px 15px;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    var(--stat-color) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.05);
}

.stat-card:hover::before {
  opacity: 0.1;
}

.stat-icon {
  font-size: 2rem;
  color: var(--stat-color);
  margin-bottom: 0.8rem;
}

.stat-number {
  font-size: 2rem;
  font-weight: 700;
  color: #E6EDF3;
  margin-bottom: 0.5rem;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.stat-label {
  color: #A3B3BC;
  font-size: 0.9rem;
}

.interests-section {
  text-align: center;
}

.interests-section h3 {
  color: #E6EDF3;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.interests-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.interest-tag {
  background: rgba(79, 193, 255, 0.1);
  color: #4FC1FF;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(79, 193, 255, 0.2);
}

.interest-tag:hover {
  background: rgba(79, 193, 255, 0.2);
  transform: translateY(-2px);
}

@media (max-width: 968px) {
  .stats-grid {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }

  .stat-card {
    min-width: 160px;
    flex: 1 1 calc(50% - 15px);
  }
}

@media (max-width: 576px) {
  .stat-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .sobre-section {
    padding: 60px 0;
  }

  .stats-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .sobre-intro {
    font-size: 1.2rem;
  }

  .sobre-description {
    font-size: 1rem;
  }

  .interests-grid {
    gap: 10px;
  }

  .interest-tag {
    padding: 8px 16px;
  }
} 