.transition-wrapper {
  position: relative;
  width: 100%;
}

.section-content {
  position: relative;
  width: 100%;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.section-content.section-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Adicione um espaço entre as seções */
section {
  padding: 80px 0;
  position: relative;
}

/* Efeito de fade entre seções */
section::before {
  content: '';
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, #0A0F16, transparent);
  pointer-events: none;
}

section::after {
  content: '';
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, #0A0F16, transparent);
  pointer-events: none;
}

@media (max-width: 768px) {
  section {
    padding: 60px 0;
  }
  
  section::before,
  section::after {
    height: 60px;
  }
} 