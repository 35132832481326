.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  background: transparent;
  backdrop-filter: blur(0);
  height: 80px;
  display: flex;
  align-items: center;
}

.header.scrolled {
  background: rgba(10, 15, 22, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  height: 70px;
}

.header-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  position: relative;
  z-index: 2;
}

.logo-text {
  color: #4FC1FF;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Fira Code', monospace;
  position: relative;
}

.logo-text::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #4FC1FF, transparent);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.logo:hover .logo-text::after {
  transform: scaleX(1);
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-menu ul {
  display: flex;
  gap: 2rem;
  list-style: none;
}

.nav-menu a {
  color: #E6EDF3;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
}

.nav-menu a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #4FC1FF;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.nav-menu a:hover {
  color: #4FC1FF;
}

.nav-menu a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  gap: 6px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 2;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 2px;
  background: #4FC1FF;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background: rgba(10, 15, 22, 0.95);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .menu-toggle.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menu-toggle.active span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
} 